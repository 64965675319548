import MicroModal from 'micromodal';
import './assets/style.scss';

MicroModal.init({
    openTrigger: 'data-custom-open', // [3]
    closeTrigger: 'data-custom-close', // [4]
    disableScroll: true, // [5]
    disableFocus: false, // [6]
    awaitOpenAnimation: false, // [7]
    awaitCloseAnimation: false, // [8]
    debugMode: false // [9]
});

setTimeout(function(){ MicroModal.show('modal-popup') }, 5000);
